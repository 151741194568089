import { Component, Input } from '@angular/core';

@Component({
    selector: 'dwin-title-underlined',
    templateUrl: './dwin-title-underlined.component.html',
    styleUrls: ['./dwin-title-underlined.component.scss'],
})
export class DwinTitleUnderlinedComponent {
    @Input() title = '';

    constructor() {}
}
