import { Component, Input } from '@angular/core';
import { Reference, ReferenceType } from '../../../../graphql-generated';
import { PopoverController } from '@ionic/angular';
import { ReferenceContentItem } from '../reference-content/reference-content.component';
import { v4 as uuidv4 } from 'uuid';

export abstract class ReferenceBarItem {
    abstract type: ReferenceType;
    abstract iconPath: string;
    pressed: boolean = false;
    inBackground: boolean = false;
    references: ReferenceContentItem[] = [];
    UUID: string = uuidv4();

    get highlighted(): boolean {
        return false;
    }

    public hasContent(): boolean {
        return this.references?.some((i) => i.description !== '') ?? false;
    }
}

export class HintReferenceBarItem extends ReferenceBarItem {
    get highlighted(): boolean {
        return this.references?.length > 1;
    }

    type = ReferenceType.Hint;
    iconPath = 'assets/imgs/hint.svg';
}

export class DisclaimerReferenceBarItem extends ReferenceBarItem {
    type = ReferenceType.Disclaimer;
    iconPath = 'assets/imgs/disclaimer.svg';
}

export class SourcesReferenceBarItem extends ReferenceBarItem {
    type = ReferenceType.Source;
    iconPath = 'assets/imgs/sources.svg';
}

@Component({
    selector: 'reference-bar',
    templateUrl: './reference-bar.component.html',
    styleUrls: ['./reference-bar.component.scss'],
})
export class ReferenceBarComponent {
    get references(): Reference[] {
        return this._references;
    }

    @Input() set references(references: ReferenceContentItem[]) {
        this._references = references.filter((r) => this.hasReferenceContent(r));
        this.initReferenceBarItems(this.references);
    }

    @Input() defaultPressedIndex?: number;

    private _references: Reference[];

    referenceBarItems: ReferenceBarItem[] = [];

    @Input() size: 'regular' | 'short' = 'regular';

    @Input() disabled: boolean = false;

    constructor(private readonly popoverCtrl: PopoverController) {}

    async buttonClicked(referenceBarItem: ReferenceBarItem) {
        const clickedReferenceBarItem = this.referenceBarItems.find((rbi) => rbi.type === referenceBarItem.type);
        clickedReferenceBarItem.pressed = !clickedReferenceBarItem.pressed;
        const otherReferenceBarItems = this.referenceBarItems.filter((rbi) => rbi.type !== referenceBarItem.type);
        otherReferenceBarItems.forEach((rbi) => {
            rbi.pressed = false;
            rbi.inBackground = clickedReferenceBarItem.pressed;
        });
    }

    popoverDismissed() {
        this.referenceBarItems.forEach((rbi) => {
            rbi.pressed = false;
            rbi.inBackground = false;
        });
    }

    private initReferenceBarItems(references: Reference[]) {
        const getReferenceContentItemsByType = (type: ReferenceType) => {
            return references.filter((r) => r.referenceType === type);
        };

        const allReferenceBarItems = [
            new HintReferenceBarItem(),
            new DisclaimerReferenceBarItem(),
            new SourcesReferenceBarItem(),
        ];

        this.referenceBarItems = [];

        for (const referenceBarItem of allReferenceBarItems) {
            referenceBarItem.references = getReferenceContentItemsByType(referenceBarItem.type);
            // only buttons with content should be displayed
            if (referenceBarItem.hasContent()) {
                this.referenceBarItems.push(referenceBarItem);
            }
        }
    }

    closePopover() {
        this.popoverCtrl.dismiss();
    }

    private hasReferenceContent(ref: ReferenceContentItem): boolean {
        return ref.description && ref.description !== '';
    }
}
