import { NgModule, Provider } from '@angular/core';
import { LoggingModule } from '../logging/logging.module';
import { environment } from '../../environments/environment';
import { DwinSessionFactory } from './dwin-session-factory.service';
import { CurafidaKeycloakConfig } from '../kc-auth/entities/curafida-keycloak-config.type';

const providers = [
    {
        provide: 'keycloakConfig',
        useFactory: () =>
            ({
                url: environment.AUTH_SERVER_URL,
                realm: environment.AUTH_REALM,
                clientId: environment.AUTH_CLIENT,
                cordovaRedirectUri: `android-app://health.dwin/https/dwin-local-deeplink-hostname/dwin`,
                kcAdapterOnMobile: 'cordova',
            }) as CurafidaKeycloakConfig,
    },
    { provide: 'SESSION_FACTORY_INTERFACE', useClass: DwinSessionFactory },
];

@NgModule({
    imports: [LoggingModule],
})
export class AuthModule {
    static forRoot(additionalProviders?: Provider[]) {
        return {
            ngModule: AuthModule,
            providers: [...providers, ...additionalProviders],
        };
    }
}
