import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RequiredActionsGQL } from '../../graphql-generated';
import { NavigationNodeService } from '../services/navigation-node/navigation-node.service';
import { getRequiredActionsRedirectOrPass } from './required-actions.guard';

/**
 * Either redirects the user to the anonymous flow if required actions are present or let the user pass after resetting the navigation node service.
 */
export const dwinPageGuard: CanActivateFn = async (route, state) => {
    const requiredActions = inject(RequiredActionsGQL);
    const router = inject(Router);
    const navigationNodeService = inject(NavigationNodeService);

    const requiredActionsRoutingCheck = await getRequiredActionsRedirectOrPass(requiredActions, router);
    // Check explicitly for 'true' to decide weather the requiredActionsGuard would redirect the user or not.
    if (requiredActionsRoutingCheck !== true) {
        return requiredActionsRoutingCheck;
    } else {
        const passAndClearNavigationNodeService = () => {
            const hasQueryParams = Object.keys(route.queryParams).length === 0;
            if (hasQueryParams) {
                navigationNodeService.resetNavigation();
            }
            return true;
        };
        return passAndClearNavigationNodeService();
    }
};
