import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterExtension {
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;

    constructor(public router: Router) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    get url() {
        return this.router.url;
    }

    get events() {
        return this.router.events;
    }

    get getPreviousUrl(): string | undefined {
        return this.previousUrl;
    }

    navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(commands, extras);
    }

    /**
     * Reload the current page by intermediately redirecting to a loading page that just shows a loading spinner.
     * The intermediate page will not be added to navigation history.
     */
    async reloadPage() {
        let targetUrl = this.currentUrl;
        // workaround to trigger component reloading. See https://stackoverflow.com/a/47865035 for reasons.
        await this.router.navigateByUrl(`/loading`, { skipLocationChange: true });

        // redirect to startPage if otherwise it would stay on loading page
        if (targetUrl === '/loading') {
            targetUrl = this.previousUrl || '/';
        }
        return this.router.navigate([targetUrl], {
            skipLocationChange: true,
        });
    }
}
