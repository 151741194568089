// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  font-size: 19px;
  font-weight: bold;
  color: var(--ion-color-primary);
  line-height: 130%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reference-content {
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-primary);
  line-height: 150%;
  transform: translateY(calc(var(--d-spacing-single) * -1));
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/reference/reference-content/reference-content.component.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;ECDI,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,iBAAA;EDAA,aAAA;EACA,sBAAA;EACA,uBAAA;AAEJ;;AACA;ECDI,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,iBAAA;EDAA,yDAAA;AAKJ","sourcesContent":["@import 'src/mixins.scss';\n\nspan {\n    @include h1-style;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.reference-content {\n    @include p1-style;\n    transform: translateY(calc(var(--d-spacing-single) * -1));\n}\n","@mixin h1-style {\n    font-size: 19px;\n    font-weight: bold;\n    color: var(--ion-color-primary);\n    line-height: 130%;\n}\n\n@mixin p1-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-primary);\n    line-height: 150%;\n}\n\n@mixin p1-berry-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-secondary);\n    line-height: 150%;\n}\n\n@mixin p2-style {\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--ion-color-primary);\n    line-height: 150%;\n}\n\n@mixin p2-berry-style {\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--ion-color-secondary);\n    line-height: 150%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
