import { ErrorComponent } from './components/error.component';
import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DwinCommonComponentsModule } from '../common/components/dwin-common-components.module';
import { ErrorLogComponent } from './components/error-log.component';
import { ErrorModalService } from './service/error-modal.service';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, DwinCommonComponentsModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ErrorModalModule),
            multi: true,
        },
        ErrorModalService,
    ],
    declarations: [ErrorComponent, ErrorLogComponent],
    exports: [ErrorComponent],
})
export class ErrorModalModule {}
