import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { ProjectNavService } from './project-nav.service';

@Injectable({ providedIn: 'root' })
export class ProjectTitleStrategyService extends TitleStrategy {
    constructor(
        private readonly title: Title,
        private projectNavService: ProjectNavService,
    ) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        if (routerState.url.includes('/project/')) {
            if (this.projectNavService.currentProjectName)
                this.title.setTitle(this.projectNavService.currentProjectName);
        } else {
            this.title.setTitle('Dwin');
        }
    }
}
