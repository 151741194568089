import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataType, Reference, ReferenceType, StateValueInput } from 'src/app/graphql-generated';
import { ModalController } from '@ionic/angular';
import { StateValueExtension } from 'src/app/state/state-value';
import { ReferenceContentItem } from '../../../common/components/reference/reference-content/reference-content.component';
import { isStateValueValueValid } from '../../utils/state-value.utils';
import { StateValueInputSaveModalComponent } from '../state-value-input-save-modal/state-value-input-save-modal.component';

@Component({
    selector: 'dwin-state-value-input',
    templateUrl: './dwin-state-value-input.component.html',
    styleUrls: ['./dwin-state-value-input.component.scss'],
})
export class DwinStateValueInputComponent {
    aggregatedReferences: ReferenceContentItem[] = [];
    stateValueReferences: ReferenceContentItem[] = [];
    @Input() text: string;
    index = 0;
    stateValueSelection: StateValueInput[] = [];
    isValid = false;
    DataType = DataType;
    progress = 0;
    @Output() cancelled = new EventEmitter();
    @Output() results = new EventEmitter<StateValueInput[]>();

    constructor(private modalCtrl: ModalController) {}

    private _references: ReferenceContentItem[] = [];

    get references(): ReferenceContentItem[] {
        return this._references;
    }

    @Input() set references(references: ReferenceContentItem[]) {
        this._references = references;
        this.updateAggregatedReferences();
    }

    private _stateValues: StateValueExtension[];

    get stateValues(): StateValueExtension[] {
        return this._stateValues;
    }

    @Input() set stateValues(stateValues: StateValueExtension[]) {
        this._stateValues = stateValues.map((sv) => {
            return {
                state: sv.state,
                value: sv.value,
                timestamp: sv.timestamp,
                question: sv.question,
                originalStateValue: sv.value,
            };
        });
        this.updateProgressBar();
        this.updateAggregatedReferences();
    }

    async discard() {
        this.cancelled.emit();
        if (this.index > 0) {
            await this.showSaveModal();
        } else {
            await this.modalCtrl.dismiss(null, 'cancel');
        }
    }

    confirm(isLast: boolean) {
        if (!this.isValid) return;
        if (isLast && this.validateSelections()) {
            this.results.emit(this.stateValueSelection);
            return this.modalCtrl.dismiss(this.stateValueSelection, 'confirm');
        } else {
            // TODO: sinnvoll, dass das ausgeführt wird, wenn this.validateSelections() nicht true ist?
            this.index++;
            this.updateProgressBar();
            this.updateAggregatedReferences();
        }
    }

    async save() {
        await this.modalCtrl.dismiss(this.stateValueSelection, 'save');
    }

    setIsValid(isValid: boolean) {
        this.isValid = isValid;
    }

    private timeoutId: number | null = null;

    delayedSelectValue(isLast: boolean) {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            this.selectValue(isLast);
            this.timeoutId = null;
        }, 420);
    }

    selectValue(isLast: boolean) {
        if (!this.isValid) return;
        this.stateValueSelection = this.stateValueSelection.filter(
            (svs) => svs.stateId !== this.stateValues[this.index].state.id,
        );
        this.stateValueSelection.push({
            stateId: this.stateValues[this.index].state.id,
            value: this.stateValues[this.index].value,
        });
        return this.confirm(isLast);
    }

    back() {
        if (this.index > 0) {
            this.index--;
            this.updateProgressBar();
        }
        this.updateAggregatedReferences();
    }

    async showSaveModal() {
        const modal = await this.modalCtrl.create({
            component: StateValueInputSaveModalComponent,
            cssClass: 'mini-modal-bottom',
        });
        await modal.present();
        const selected = await modal.onDidDismiss<StateValueInput[]>();
        if (selected.role === 'save') {
            await this.save();
        } else if (selected.role === 'cancel') {
            this.modalCtrl.dismiss();
        }
    }

    private validateSelections(): boolean {
        let isValidSelection = true;
        for (const stateValue of this.stateValues) {
            const selection = this.stateValueSelection.find((svs) => svs.stateId === stateValue.state.id);
            isValidSelection = isValidSelection && !!selection;
        }
        return isValidSelection;
    }

    private updateAggregatedReferences() {
        this.stateValueReferences =
            this.stateValues[this.index].state.references && this.stateValues[this.index].state.references.length > 0
                ? this.addTitleToReference(this.stateValues[this.index].state.references)
                : [];
        this.aggregatedReferences = [];
        if (this.stateValueReferences && this.stateValueReferences.length > 0) {
            this.aggregatedReferences.push(...this.stateValueReferences);
        }
        if (this.references && this.references.length > 0) {
            this.aggregatedReferences.push(...this.references);
        }
    }

    private addTitleToReference(references: Reference[]): ReferenceContentItem[] {
        if (!references || references.length === 0) return;
        return references.map((r) => {
            let title: string;
            switch (r.referenceType) {
                case ReferenceType.Hint:
                    title = $localize`:@@components.state-reference-title.hint:Missing translation`;
                    break;
                case ReferenceType.Disclaimer:
                    title = $localize`:@@components.state-reference-title.disclaimer:Missing translation`;
                    break;
                case ReferenceType.Source:
                    title = $localize`:@@components.state-reference-title.sources:Missing translation`;
                    break;
            }
            return {
                title,
                referenceType: r.referenceType,
                __typename: 'Reference',
                description: r.description,
            } as ReferenceContentItem;
        });
    }

    private updateProgressBar() {
        this.progress = (this.index + 1) / this.stateValues.length;
    }

    protected readonly isStateValueValueValid = isStateValueValueValid;
}
