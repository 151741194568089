import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

/**
 * Redirects to landing-page if user is not logged in or allows to enter page referenced by route param if logged in.
 * Also, if logged in, redirects from the landing-page to the dwin-page.
 * @param route see {@link route}
 */
export const landingPageGuard: CanActivateFn = async (route): Promise<boolean | UrlTree> => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const user = await firstValueFrom(authService.loggedInUser);
    const isLoggedIn = !!user;
    const isOnLandingPage = route?.url[0]?.path === 'landing-page';
    const pass = () => true;
    const redirect = (path: string) => router.createUrlTree([path]);

    if (isOnLandingPage) {
        return isLoggedIn ? redirect('feed') : pass();
    } else {
        return isLoggedIn ? pass() : redirect('landing-page');
    }
};
