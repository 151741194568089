// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  --background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  color: var(--ion-color-primary-light);
  text-transform: none;
  font-size: medium;
}
ion-button ion-icon {
  margin-right: 0.3em;
  color: var(--ion-color-primary);
}
ion-button div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/back-button/dwin-back-button.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,qCAAA;EACA,oBAAA;EACA,iBAAA;AACJ;AACI;EACI,mBAAA;EACA,+BAAA;AACR;AAEI;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;AAAR","sourcesContent":["ion-button {\n    --background-color: white;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    font-weight: bold;\n    color: var(--ion-color-primary-light);\n    text-transform: none;\n    font-size: medium;\n\n    ion-icon {\n        margin-right: 0.3em;\n        color: var(--ion-color-primary);\n    }\n\n    div {\n        display: flex;\n        justify-content: center;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
