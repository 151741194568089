import { Injectable } from '@angular/core';
import { SessionFactoryInterface } from '../kc-auth/services';
import { Session } from './session';
import { TokenSet } from '../kc-auth/entities/token-set';
import { User } from './user';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class DwinSessionFactory implements SessionFactoryInterface<Session> {
    create(tokenSet: TokenSet): Session {
        const session = new Session();
        session.user = this.getUserFromAccessToken(tokenSet.access_token);
        if (!session.user) {
            throw new Error('[processTokenSet] User Object could not be constructed from TokenSet -> Logout');
        }
        session.tokenSet = tokenSet;
        return session;
    }

    /**
     * Constructs a user object from a given access token.
     * @param accessToken a raw (string encoded) access token obtained by keycloak
     */
    private getUserFromAccessToken(accessToken: string): User {
        const user = new User();
        try {
            const decoded: any = jwtDecode(accessToken);
            user.kcId = decoded.sub;
            user.username = decoded.preferred_username;
            user.email = decoded.email;
            user.emailVerified = decoded.email_verified;
            user.disabled = false;
        } catch (error) {
            throw new Error('Constructing user object from access token payload failed!');
        }
        return user;
    }
}
