import { BeforeSessionChangeListener } from '../kc-auth/services/before-session-change-listener';
import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { Session } from '../auth/session';
import { NavigationEnd, Router } from '@angular/router';
import { sha256 } from 'js-sha256';

@Injectable()
export class TrackingService implements BeforeSessionChangeListener {
    constructor(
        private matomoTracker: MatomoTracker,
        private router: Router,
    ) {
        this.init();
    }

    sessionChanged(session: Session): void {
        this.trackUserId(session);
    }

    private trackUserId(session: Session): void {
        const userId = session?.user?.kcId;
        if (userId) {
            const userIdHash = sha256.hmac('a90ef404-9aa4-4ce0-9a98-06ebf75a1af6', userId);
            this.matomoTracker?.setUserId(userIdHash);
        }
    }

    async init() {
        this.router.events.subscribe(async (event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('/loading')) {
                    const userId = await this.matomoTracker?.getUserId();
                    this.matomoTracker?.trackEvent('LoadingPage', 'Loading', userId);
                }
            }
        });
    }
}
