// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-block-text {
  margin-top: var(--d-spacing-single);
}

.description-title {
  font-size: 19px;
  font-weight: bold;
  color: var(--ion-color-primary);
  line-height: 130%;
  transform: translateY(var(--d-spacing-single));
}

.description-block-context {
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-primary);
  line-height: 150%;
  margin-bottom: var(--d-spacing-triple);
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/description-block/dwin-description-block.component.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACI,mCAAA;AADJ;;AAIA;ECLI,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,iBAAA;EDIA,8CAAA;AAEJ;;AACA;ECHI,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,iBAAA;EDEA,sCAAA;AAKJ","sourcesContent":["@import 'src/mixins.scss';\n\n.description-block-text {\n    margin-top: var(--d-spacing-single);\n}\n\n.description-title {\n    @include h1-style;\n    transform: translateY(var(--d-spacing-single));\n}\n\n.description-block-context {\n    @include p1-style;\n    margin-bottom: var(--d-spacing-triple);\n}\n","@mixin h1-style {\n    font-size: 19px;\n    font-weight: bold;\n    color: var(--ion-color-primary);\n    line-height: 130%;\n}\n\n@mixin p1-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-primary);\n    line-height: 150%;\n}\n\n@mixin p1-berry-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-secondary);\n    line-height: 150%;\n}\n\n@mixin p2-style {\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--ion-color-primary);\n    line-height: 150%;\n}\n\n@mixin p2-berry-style {\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--ion-color-secondary);\n    line-height: 150%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
