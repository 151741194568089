// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-wrapper-content {
  width: 100%;
  min-height: var(--d-spacing-double);
}

.invisible {
  display: none;
}

.media-loading-spinner {
  height: 25vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-loading-spinner ion-spinner {
  height: 5em;
  width: 5em;
}

.media-image {
  height: 25vh;
  width: 100%;
}

.media-image-large {
  height: 90%;
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/media-wrapper/media-wrapper.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mCAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,UAAA;AACR;;AAGA;EACI,YAAA;EACA,WAAA;AAAJ;;AAGA;EACI,WAAA;EACA,WAAA;AAAJ;;AAGA;EACI;IACI,UAAA;EAAN;EAEE;IACI,UAAA;EAAN;AACF;AAGA;EACI,sBAAA;AADJ","sourcesContent":[".media-wrapper-content {\n    width: 100%;\n    min-height: var(--d-spacing-double);\n}\n\n.invisible {\n    display: none;\n}\n\n.media-loading-spinner {\n    height: 25vh;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    ion-spinner {\n        height: 5em;\n        width: 5em;\n    }\n}\n\n.media-image {\n    height: 25vh;\n    width: 100%;\n}\n\n.media-image-large {\n    height: 90%;\n    width: 100%;\n}\n\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n\n.fade-in {\n    animation: fadeIn 0.5s;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
