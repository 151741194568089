import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoggingService } from './logging.service';

@NgModule({
    declarations: [],
    imports: [IonicModule, FormsModule, CommonModule],
    providers: [{ provide: 'LOGGER_FACTORY', useClass: LoggingService }],
})
export class LoggingModule {}
