import { User } from './user';
import { Session as KcAuthSession } from '../kc-auth/entities/session';
import { TokenSet } from '../kc-auth/entities/token-set';

export class Session extends KcAuthSession {
    /**
     * A successful authenciation results in a TokenSet containing JSON Web Tokens.
     * There is at least an access_token. But mostly also a refresh_token.
     * Sometimes also an id_token.
     */
    tokenSet: TokenSet = {
        access_token: null,
    };

    /**
     * User object extracted from access token payload.
     */
    user?: User = null;
}
