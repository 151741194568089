import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ProjectNavService {
    private activeUserProjectId: string = undefined;
    currentProjectName?: string = undefined;
    constructor() {}

    getActiveUserProjectId(): string {
        return this.activeUserProjectId;
    }

    setActiveUserProjectId(userProjectId: string) {
        this.activeUserProjectId = userProjectId;
    }

    clearActiveUserProjectId() {
        this.activeUserProjectId = undefined;
    }
}
