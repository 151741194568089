import { Component, Input } from '@angular/core';
import { Reference } from '../../../../graphql-generated';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'reference-modal',
    templateUrl: './reference-modal.component.html',
    styleUrls: ['./reference-modal.component.scss'],
})
export class ReferenceModalComponent {
    @Input() reference: Reference;

    constructor(private modalCtrl: ModalController) {}

    close() {
        this.modalCtrl.dismiss(null, 'close');
    }

    next() {
        this.modalCtrl.dismiss(null, 'next');
    }
}
