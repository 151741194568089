// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `markdown .markdown-text-wrapper.noTopMargin > :nth-child(1) {
  margin-top: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/markdown/markdown.component.scss"],"names":[],"mappings":"AAMQ;EACI,eAAA;AALZ","sourcesContent":["// WARNING: Styles defined here can leak into other components because we had to change ViewEncapsulation to none.\n// So please nest everything inside the markdown selector to avoid conflicts.\n// More info: https://angular.io/api/core/ViewEncapsulation\nmarkdown {\n    .markdown-text-wrapper.noTopMargin {\n        // matches only the first first-child and isn't recursive (as opposed to :first-child).\n        & > :nth-child(1) {\n            margin-top: 1px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
