import { Injectable } from '@angular/core';
import {
    AddStateValuesGQL,
    RequiredAction,
    RequiredActionsGQL,
    RequiredActionType,
    StateValueInput,
} from '../../../graphql-generated';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { DwinStateValueInputComponent } from '../../../state-value/components/state-value-input/dwin-state-value-input.component';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RequiredActionsService {
    reqActionsSub: Subscription;

    requiredActionChanges$: BehaviorSubject<RequiredAction[]> = new BehaviorSubject<RequiredAction[]>(null);

    constructor(
        private readonly requiredActions: RequiredActionsGQL,
        private readonly addStateValuesGQL: AddStateValuesGQL,
        private readonly modalCtrl: ModalController,
        private readonly router: Router,
    ) {}

    async checkForRequiredActions() {
        this.reqActionsSub?.unsubscribe();
        this.reqActionsSub = this.requiredActions.fetch().subscribe(async (ra) => {
            const reqActions: RequiredAction[] = ra.data.requiredActions;
            this.requiredActionChanges$.next(reqActions);
        });
    }

    async checkIfDwinCreated() {
        this.reqActionsSub?.unsubscribe();
        this.reqActionsSub = this.requiredActions.fetch().subscribe(async (ra) => {
            const reqActions: RequiredAction[] = ra.data.requiredActions.filter(
                (ra) => ra.requiredActionType === RequiredActionType.StateCollection,
            );
            this.requiredActionChanges$.next(reqActions);
            if (!reqActions || (reqActions && reqActions.length === 0)) {
                this.router.navigate(['dwin-created'], { replaceUrl: true });
            }
        });
    }

    async queryRequiredActionsIfPresent() {
        this.reqActionsSub?.unsubscribe();
        this.reqActionsSub = this.requiredActions.fetch().subscribe(async (ra) => {
            const reqActions: RequiredAction[] = ra.data.requiredActions;
            this.requiredActionChanges$.next(reqActions);
            await this.handleActions(reqActions);
        });
    }

    async handleActions(reqActions: RequiredAction[]) {
        if (!reqActions || (reqActions && reqActions.length === 0)) {
            await this.router.navigate(['dwin-created'], { replaceUrl: true });
        } else {
            let stateValues = [];
            for (const reqAction of reqActions) {
                if (reqAction.requiredActionType === RequiredActionType.StateCollection) {
                    stateValues.push(...reqAction.stateValues);
                }
            }
            if (stateValues.length > 0) {
                const modal = await this.modalCtrl.create({
                    component: DwinStateValueInputComponent,
                    componentProps: { stateValues },
                });
                modal.present();
                const selected = await modal.onDidDismiss<StateValueInput[]>();
                if (selected.role === 'confirm' || selected.role === 'save') {
                    if (selected.data?.length > 0)
                        await this.updateStateValueAndCheckRequiredActionsDwin(selected.data);
                }
            }
        }
    }

    private async updateStateValueAndCheckRequiredActionsDwin(stateValueInputs: StateValueInput[]) {
        await firstValueFrom(this.addStateValuesGQL.mutate({ stateValueInputs }));
        await this.checkIfDwinCreated();
    }
}
