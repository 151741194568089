import { Injectable } from '@angular/core';
import { Session as DwinSession } from './session';
import { ReplaySubject } from 'rxjs';
import { User } from './user';
import { AbstractAuthService } from 'src/app/kc-auth/services';
import { CordovaOptions } from '@ionic-native/core';

@Injectable({ providedIn: 'root' })
export class AuthService extends AbstractAuthService<DwinSession> {
    loggedInUser = new ReplaySubject<User>(1);

    protected init(): void {
        super.init();
        this.session$.subscribe((s) => {
            this.loggedInUser.next(s?.user || null);
            const isOnLandingPage = this.router.routerState.snapshot.url === '/landing-page';
            if (s === null) {
                this.router.navigate(['landing-page'], { replaceUrl: true });
            } else if (isOnLandingPage) {
                this.router.navigate(['feed'], { replaceUrl: true });
            }
        });
    }

    register() {
        const loginOptions = {
            cordovaOptions: { zoom: 'no', toolbar: 'no' } as CordovaOptions,
        } as Keycloak.KeycloakLoginOptions;
        this.keycloakJsInstance.register(loginOptions);
    }
}
