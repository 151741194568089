import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { RequiredAction, RequiredActionsGQL, RequiredActionType } from '../../graphql-generated';

export const getRequiredActionsRedirectOrPass: (
    requiredActions: RequiredActionsGQL,
    router: Router,
) => Promise<boolean | UrlTree> = async (requiredActions: RequiredActionsGQL, router: Router) => {
    const pass = () => true;
    const redirectToAnonymousFlow = () => router.createUrlTree(['anonymous-flow']);

    const ra = await firstValueFrom(requiredActions.fetch());
    const reqActions: RequiredAction[] = ra.data.requiredActions;
    const hasRequiredActions =
        reqActions?.filter((ra) => ra.requiredActionType === RequiredActionType.StateCollection).length > 0;

    return hasRequiredActions ? redirectToAnonymousFlow() : pass();
};

/**
 * Redirects the user to the required action flow if the user has required actions. Currently only checks for type StateCollection.
 */
export const requiredActionsGuard: CanActivateFn = () => {
    const requiredActions = inject(RequiredActionsGQL);
    const router = inject(Router);
    return getRequiredActionsRedirectOrPass(requiredActions, router);
};

export const getBuddyDisclaimerRedirectOrPass: (
    requiredActions: RequiredActionsGQL,
    router: Router,
) => Promise<boolean | UrlTree> = async (requiredActions: RequiredActionsGQL, router: Router) => {
    const pass = () => true;
    const redirectToBuddyTermsOfUse = (stateId: string, disclaimerText: string) =>
        router.createUrlTree(['chatbot-terms-of-use'], {
            queryParams: { disclaimerState: stateId, disclaimerText },
        });

    const ra = await firstValueFrom(requiredActions.fetch());
    const reqActions: RequiredAction[] = ra.data.requiredActions;
    if (reqActions && reqActions.length > 0) {
        const buddyDisclaimerReqAction = reqActions.find(
            (ra) => ra.requiredActionType === RequiredActionType.BuddyDisclaimer,
        );
        if (buddyDisclaimerReqAction?.stateValues && buddyDisclaimerReqAction.stateValues.length > 0) {
            const buddyDisclaimerStateValue = buddyDisclaimerReqAction.stateValues[0];
            const buddyDisclaimerText =
                buddyDisclaimerStateValue?.state?.description?.length > 0
                    ? buddyDisclaimerStateValue.state.description[0].description
                    : '';
            return redirectToBuddyTermsOfUse(buddyDisclaimerStateValue?.state?.id, encodeURI(buddyDisclaimerText));
        }
    }
    return pass();
};

/**
 * Redirects the user to the buddy disclaimer page if the user has not yet accepted the terms of use.
 */
export const buddyDisclaimerGuard: CanActivateFn = () => {
    const requiredActions = inject(RequiredActionsGQL);
    const router = inject(Router);
    return getBuddyDisclaimerRedirectOrPass(requiredActions, router);
};
