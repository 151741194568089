import { Component, Input } from '@angular/core';

export interface SimpleCardItem<T> {
    title: string;
    value: T;
    disabled?: boolean;
}

@Component({
    selector: 'dwin-simple-card',
    templateUrl: './dwin-simple-card.component.html',
    styleUrls: ['./dwin-simple-card.component.scss'],
})
export class DwinSimpleCardComponent<T> {
    @Input() simpleCardItem: SimpleCardItem<T>;
    @Input() buttonColor: 'red' | 'white' = 'red';
}
