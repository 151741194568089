import { Component, Input } from '@angular/core';

@Component({
    selector: 'dwin-back-button',
    templateUrl: './dwin-back-button.component.html',
    styleUrls: ['./dwin-back-button.component.scss'],
})
export class DwinBackButtonComponent {
    @Input() previousText = $localize`:@@components.common.dwin-back-button.previous-text:Missing translation`;

    constructor() {}
}
