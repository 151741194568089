import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { State, StateNumeric } from 'src/app/graphql-generated';
import { LoggerFactory } from '../../../kc-auth/services/logger-factory';
import { Logger } from '../../../logging/logging.service';
import { ValueInterval } from './value.interval';

@Component({
    selector: 'range-with-intervals',
    templateUrl: './range-with-intervals.component.html',
    styleUrls: ['./range-with-intervals.component.scss'],
})
export class RangeWithIntervalsComponent implements AfterViewInit {
    protected instanceId = Math.round(Math.random() * 1000000);
    private log: Logger;
    private INTERVAL_GAP_WIDTH_PERCENTAGE = 1.5;

    constructor(@Inject('LOGGER_FACTORY') private loggerFactory: LoggerFactory) {
        this.log = this.loggerFactory.getLogger(this.constructor.name);
    }

    ngAfterViewInit(): void {
        this.initView();
    }

    pinId = 'pin-' + this.instanceId;

    get stateValue(): { value: string; state: Partial<State> } {
        return this._stateValue;
    }

    @Input() displayStateTitle = false;

    @Input() set stateValue(value: { value: string; state: Partial<State> }) {
        if (value.state.__typename !== 'StateNumeric') return;
        this._stateValue = value;
        this.pinId = `${this.stateValue.state.id}-${this.pinId}`;
        this.configureView();
        // run on next tick, so that the ui was rendered after configureView
        setTimeout(() => this.initView(), 1);
    }

    private _stateValue: { value: string; state: Partial<State> };

    public intervals: ValueInterval[];

    private configureView() {
        if (this.stateValue.state.__typename === 'StateNumeric') {
            const range = (this.stateValue.state as StateNumeric)?.valueRange;
            if (range) {
                const intervals = range?.intervals;
                if (intervals) {
                    this.intervals = intervals.map((i) => ValueInterval.fromJSON(i));
                } else {
                    this.intervals = [ValueInterval.fromJSON({ start: range.min, end: range.max })];
                }
                this.intervals.map((i) => {
                    i.widthPercentage = i.widthInPercent(
                        this.absRange(),
                        100 - this.INTERVAL_GAP_WIDTH_PERCENTAGE * (this.intervals.length - 1),
                    );
                });
                this.intervals = this.intervals.flatMap((interval, index) => {
                    return index < intervals.length - 1
                        ? [
                              interval,
                              ValueInterval.fromJSON({
                                  start: interval.end,
                                  end: intervals[index + 1].start,
                                  widthPercentage: this.INTERVAL_GAP_WIDTH_PERCENTAGE,
                                  isGap: true,
                              }),
                          ]
                        : [interval];
                });
            }
        }
    }

    initView() {
        if (!this._stateValue) return;
        const pin: HTMLElement = document.querySelector(`#${this.pinId}`);
        if (pin === null) {
            this.log.error(`Pin not found in document.`);
            return;
        }
        const pinPosPercent = this.calcPinPosPercent(+this.stateValue.value);
        pin.style.setProperty('--pin-pos', pinPosPercent + '%');
        const pinPos = pin.getBoundingClientRect().left + 15;
        document.querySelectorAll(`#range-${this.instanceId} .interval`).forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.left <= pinPos) {
                if (rect.right <= pinPos) {
                    section.classList.add('lesserSection');
                } else {
                    section.classList.remove('lesserSection');
                    let pinBgWidth = pinPos - rect.left;
                    pin.style.setProperty('--pin-bg-width', pinBgWidth + 'px');
                    let pinBgLeftOffset = pinPos - rect.left - 15;
                    pin.style.setProperty('--pin-bg-left-offset', pinBgLeftOffset + 'px');
                }
            } else {
                section.classList.remove('lesserSection');
            }
        });
    }

    absRange() {
        const range = Math.abs(this.getMax() - this.getMin());
        const spaceBetween = this.intervals.reduce((acc, s, index) => {
            if (index === 0) return 0;
            return acc + Math.abs(s.start - this.intervals[index - 1].end);
        }, 0);
        return range - spaceBetween;
    }

    private getMax() {
        if (this.intervals) return Math.max(...this.intervals.map((s) => s.end));
    }

    private getMin() {
        if (this.intervals) return Math.min(...this.intervals.map((s) => s.start));
    }

    calcPinPosPercent(val: number): number {
        let widthOfIntervalsBefore = 0;
        const intervalContainingVal = this.intervals?.find((i) => {
            const width = i.widthPercentage;
            if (i.contains(val)) return true;
            if (val > i.end) widthOfIntervalsBefore += width;
            return false;
        });
        if (!intervalContainingVal) {
            this.log.warn(`Value ${val} is not in any interval.`);
            return widthOfIntervalsBefore;
        }
        const relWidth =
            ((val - intervalContainingVal.start) / (intervalContainingVal.end - intervalContainingVal.start)) *
            intervalContainingVal.widthPercentage;
        return widthOfIntervalsBefore + relWidth;
    }
}
