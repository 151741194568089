import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

/**
 * Displays images. If the url points to a json file (ending with ".json") it assumes that the media is a lottie file,
 * in any other case it assumes it's an image.
 */
@Component({
    selector: 'media-wrapper',
    templateUrl: './media-wrapper.component.html',
    styleUrls: ['./media-wrapper.component.scss'],
})
export class MediaWrapperComponent {
    imageUrl: string;
    lottieOptions: AnimationOptions = {};
    hasMediaFinishedLoading = false;
    isRetry = false;

    @Input() mediaSize: 'small' | 'medium' | 'large' = 'medium';

    private _mediaUrl: string;
    @Input() set mediaUrl(url: string) {
        if (this.isRetry) this._mediaUrl = undefined;
        if (!url || url === '' || url === this.mediaUrl || this.hasMediaFinishedLoading) return;
        this._mediaUrl = url;
        if (url.endsWith('.json')) {
            this.lottieOptions = { path: url };
        } else {
            this.imageUrl = url;
        }
        // For some reason on ios neither the "dataReady" nor the "domLoaded" lottie events are fired when loading the
        // first lottie resource on the initial start up after the installation of the app.
        // This part retries to set the resource once which seems to solve this issue. On subsequent restarts this does not seem to be an issue.
        if (!this.isRetry && !this.hasMediaFinishedLoading) {
            setTimeout(() => {
                if (this.hasMediaFinishedLoading) return;
                this.isRetry = true;
                this.mediaUrl = url;
            }, 5000);
        }
    }

    get mediaUrl(): string {
        return this._mediaUrl;
    }

    mediaFinishedLoading() {
        this.hasMediaFinishedLoading = true;
    }
}
