export class ValueInterval {
    start: number;
    end: number;
    labels?: string[];
    widthPercentage?: number;
    isGap?: boolean;

    contains(value: number): boolean {
        return value >= this.start && value <= this.end;
    }

    widthInPercent(totalWidth: number, totalWidthPercentage: number): number {
        if (this.widthPercentage) return this.widthPercentage * totalWidthPercentage * 0.01;
        const sectionRange = Math.abs(this.end - this.start);
        return (sectionRange / totalWidth) * totalWidthPercentage;
    }

    static fromJSON(json: Partial<ValueInterval>): ValueInterval {
        const interval = new ValueInterval();
        interval.start = json.start;
        interval.end = json.end;
        interval.labels = json.labels;
        interval.widthPercentage = json.widthPercentage;
        interval.isGap = json.isGap;
        return interval;
    }
}
