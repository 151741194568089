import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'dwin-label-value',
    templateUrl: './dwin-label-value.component.html',
    styleUrls: ['./dwin-label-value.component.scss'],
})
export class DwinLabelValueComponent {
    @Input() label = '';
    @Input() value = '';
    @Input() showEditButton = false;

    @Output() editClicked = new EventEmitter<any>();

    constructor() {}

    onEditClicked() {
        this.editClicked.emit();
    }
}
