// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dwin-title-underlined {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: hidden hidden solid hidden;
  border-color: black;
  border-width: 1px;
  font-weight: bold;
  color: var(--ion-color-primary);
  font-size: medium;
  padding-bottom: calc(var(--d-spacing-single) / 2);
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/title-underlined/dwin-title-underlined.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EAEA,wCAAA;EACA,mBAAA;EACA,iBAAA;EAEA,iBAAA;EACA,+BAAA;EAEA,iBAAA;EACA,iDAAA;AAFJ","sourcesContent":[".dwin-title-underlined {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n\n    border-style: hidden hidden solid hidden;\n    border-color: black;\n    border-width: 1px;\n\n    font-weight: bold;\n    color: var(--ion-color-primary);\n\n    font-size: medium;\n    padding-bottom: calc(var(--d-spacing-single) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
