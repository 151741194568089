import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { ProjectNavService } from '../services/project-nav.service';
import { getUrlSegmentsFromString } from '../../utils/string-processing';

export const projectNavGuard: CanActivateFn = async (route): Promise<boolean | UrlTree> => {
    const router = inject(Router);
    const projectNavService = inject(ProjectNavService);

    const comingFrom: string[] = getUrlSegmentsFromString(router.url);
    const goingTo: string[] = route?.url.map((segment) => segment.path);

    const comingFromUserProjectPage = comingFrom.includes('project');
    const goingToUserProjectPage = goingTo.includes('project');
    const goingToProjectsPage = goingTo.length === 0;

    const pass = () => true;
    const redirectToUserProject = () =>
        router.createUrlTree(['projects', 'project', projectNavService.getActiveUserProjectId()], {
            queryParams: { backBehaviour: 'projects' },
        });

    if (goingToUserProjectPage) {
        const userProjectIdIndex = goingTo.indexOf('project') + 1;
        projectNavService.setActiveUserProjectId(goingTo[userProjectIdIndex]);
    } else if (comingFromUserProjectPage && goingToProjectsPage) {
        projectNavService.clearActiveUserProjectId();
    }

    return goingToProjectsPage && projectNavService.getActiveUserProjectId() ? redirectToUserProject() : pass();
};
