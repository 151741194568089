import * as log from 'loglevel';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type Logger = log.Logger;

export class LogObject {
    loggerName: string;
    logLevel: string;
    message: any;
    timestamp: Date;
}

export enum LogLevel {
    TRACE = 0,
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4,
    SILENT = 5,
}

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private loggingSubject = new Subject<LogObject>();
    private useLogViewer = false;
    private switchLogView = new Subject<boolean>();

    constructor() {}

    /**
     * This sets the log level for the default root logger
     */
    public async setGlobalLogLevel(logLevel: LogLevel, enableLogViewer: boolean) {
        this.useLogViewer = enableLogViewer;
        if (logLevel === log.getLevel() || !Object.values(LogLevel).includes(logLevel)) {
            return;
        }
        log.setLevel(logLevel);
    }

    public getGlobalLogLevelStr(): string {
        return LogLevel[this.getGlobalLogLevel()];
    }

    public getGlobalLogLevel(): LogLevel {
        return log.getLevel();
    }

    public getLogger(loggerName: string): Logger {
        const self = this;
        const logger = log.getLogger(loggerName);
        const originalFactory = log.methodFactory;
        logger.methodFactory = (methodName, logLevel, loggerNameIntern) => {
            const rawMethod = originalFactory(methodName, logLevel, loggerNameIntern);
            if (this.useLogViewer) {
                return (...message) => {
                    /*
                     * There is a pretty big caveat here!
                     * If a wrapping function like this is used to process the log message in any way,
                     * then the log filename and line numbers cannot be preserved.
                     * Therefore the log messages are not sent to the internal log viewer on non-mobile devices
                     * e.g. Desktop Browsers, to preserve the line numbers.
                     *
                     * Basically we have to choose between:
                     *  1. Preserve line numbers and do not process the log message in any sense.
                     *     E.g. sending it to additionally targets or manipulating it.
                     *  2. Process and manipulate it with a wrapper function like this and loose
                     *     the file and line number the log orutiliginally was triggered.
                     */
                    const stringMessage = null;
                    if (message.length === 1) {
                        try {
                            // TODO: fix!
                            // stringMessage = util.inspect(message[0], false, this.inspectDept, false);
                        } catch (error) {
                            console.error(error);
                            return;
                        }
                    } else {
                        try {
                            // TODO: fix!
                            // stringMessage = util.inspect(message, false, this.inspectDept, false);
                        } catch (err) {
                            console.error(err);
                            return;
                        }
                    }
                    self.loggingSubject.next({
                        loggerName: loggerNameIntern,
                        logLevel: methodName,
                        message: stringMessage,
                        timestamp: new Date(),
                    } as LogObject);
                    rawMethod(`[${loggerNameIntern as string}]`, ...message);
                };
            } else {
                // A "simple" bind without accessing the log message preserves the line numbering
                return rawMethod.bind(console, `[${loggerNameIntern as string}]`);
            }
        };
        // The next line has to be called for the methodfactory to be used
        logger.setLevel(logger.getLevel(), false);
        return logger;
    }

    public getLoggingSubject(): Subject<LogObject> {
        return this.loggingSubject;
    }

    publishSwitchLogViewState(isLogViewEnabled: boolean) {
        this.switchLogView.next(isLogViewEnabled);
    }

    getSwitchLogView(): Subject<boolean> {
        return this.switchLogView;
    }
}
