import { Component, Input } from '@angular/core';
import { Reference, ReferenceType } from '../../../../graphql-generated';

export interface ReferenceContentItem extends Reference {
    title?: string;
    header?: string;
}

@Component({
    selector: 'reference-content',
    templateUrl: './reference-content.component.html',
    styleUrls: ['./reference-content.component.scss'],
})
export class ReferenceContentComponent {
    private _references: ReferenceContentItem[];
    get references(): ReferenceContentItem[] {
        return this._references;
    }

    @Input() set references(references: ReferenceContentItem[]) {
        if (!references) {
            references = [];
        } else if (references.length > 0) {
            this.type = references[0].referenceType;
            references = references.filter((r) => r.referenceType === this.type);
            references = references.filter((r) => this.hasReferenceContent(r));
        }
        this._references = references;
    }

    private hasReferenceContent(ref: ReferenceContentItem): boolean {
        return ref.description && ref.description !== '';
    }

    ReferenceType = ReferenceType;
    type: ReferenceType;
}
