import { Component, Input } from '@angular/core';

@Component({
    selector: 'dwin-description-block',
    templateUrl: './dwin-description-block.component.html',
    styleUrls: ['./dwin-description-block.component.scss'],
})
export class DwinDescriptionBlockComponent {
    @Input() title: string;
    @Input() description: string;

    constructor() {}
}
