import {
    DataType,
    StateNumeric,
    StateSelection,
    StateValue,
    UnknownAndKnownStateValuesFragment,
} from '../../graphql-generated';
import { StateValueExtension } from '../../state/state-value';

export function removeSubscores(
    unknownAndKnownStateValues: UnknownAndKnownStateValuesFragment,
): UnknownAndKnownStateValuesFragment {
    const filteredKnownStateValues = unknownAndKnownStateValues.knownStateValues.filter((sv) => !sv.state.isSubscore);
    const filteredUnknownStateValues = unknownAndKnownStateValues.unknownStateValueStates.filter(
        (svs) => !svs.isSubscore,
    );

    return { knownStateValues: filteredKnownStateValues, unknownStateValueStates: filteredUnknownStateValues };
}

export function isStateValueValueValid(stateValue: StateValueExtension | StateValue): boolean {
    if (!stateValue.value || stateValue.value === '') return false;
    if (stateValue.state.dataType === DataType.Selection) {
        const selectionOptions = (stateValue.state as StateSelection).selectionOptions.map((so) => so.id);
        return selectionOptions.includes(stateValue.value);
    } else if (stateValue.state.dataType === DataType.Numeric) {
        if ((stateValue.state as StateNumeric).valueRange) {
            return (
                Number(stateValue.value) >= (stateValue.state as StateNumeric).valueRange.min &&
                Number(stateValue.value) <= (stateValue.state as StateNumeric).valueRange.max
            );
        }
    }
    return true;
}
