import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorComponent } from '../components/error.component';
import { ReplaySubject } from 'rxjs';

export interface ErrorDialogParams {
    error: Error;
    /**
     * Show a reload button that allows to reload the current page. Defaults to true.
     */
    showRetryBtn?: boolean;
    /**
     * Either show a logout button or a back button.
     */
    escapeAction?: 'logout' | 'back' | 'dismiss';
    /**
     * Allows to display a more specific message to the user.
     */
    displayMessage?: string;
    /**
     * Allow the user to hide the error message. Defaults to false.
     */
    allowDismiss?: boolean;
    /**
     * A custom retry label and/or action. If not set the defaults are the label "Reload"
     * and a reload of the page on button click.
     */
    customRetryButton?: CustomRetryButton;
}

export interface CustomRetryButton {
    label?: string;
    retryAction?: () => void;
}

@Injectable({ providedIn: 'root' })
export class ErrorModalService {
    private modal: HTMLIonModalElement | null = null;
    private errorDialogParams$ = new ReplaySubject<ErrorDialogParams>();
    private isModalShown = false;

    constructor(private modalCtrl: ModalController) {}

    /**
     * Opens a new modal to show the error or appends a new message to an already opened error modal.
     * @param params
     */
    async showErrorDialog(params: ErrorDialogParams) {
        params.showRetryBtn = params.showRetryBtn ?? true; //default to true
        params.allowDismiss = params.allowDismiss ?? false; //default to false
        this.errorDialogParams$.next(params);
        if (!this.modal && !this.isModalShown) {
            this.isModalShown = true;
            this.modal = await this.modalCtrl.create({
                id: 'ErrorComponent',
                component: ErrorComponent,
                componentProps: { errorDialogParams$: this.errorDialogParams$ },
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                cssClass: 'mini-modal', // needs to be defined in global.scss (see docs of ion-modal)
                backdropDismiss: !params.allowDismiss,
                // allows to dismiss the modal only if role is set to 'hide' or 'retry'. This prevents swipe to close.
                canDismiss: async (_data, role) => params.allowDismiss || ['hide', 'retry'].includes(role),
            });
            this.modal.onDidDismiss().then(() => {
                this.isModalShown = false;
                this.modal = null; // reset, so next time a new modal will be created if showErrorDialog is being called
                this.errorDialogParams$ = new ReplaySubject<ErrorDialogParams>();
            });
            await this.modal.present();
        }
    }

    async hide() {
        await this.modal?.dismiss(null, 'hide');
    }
}
