import { Injectable, OnDestroy } from '@angular/core';
import { GetAllNavigationNodesGQL, NavigationNode, NavigationNodeType } from 'src/app/graphql-generated';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReplaySubject, Subscription } from 'rxjs';
import { NavigationHeaderItem } from 'src/app/dwin/components/navigation-header/dwin-navigation-header.component';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationNodeService implements OnDestroy {
    navigationHeaderItems: NavigationHeaderItem[] = [];
    headerItemIndex$ = new ReplaySubject<number>(1);

    private loggedInUserSubscription: Subscription;
    private routeSubscription: Subscription;
    private navigationNodes: NavigationNode[];
    private initialized = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private getAllNavigationNodesGQL: GetAllNavigationNodesGQL,
    ) {
        this.loggedInUserSubscription = this.authService.loggedInUser.subscribe((user) => {
            if (!user) {
                console.error(`DwinPage: Not logged in`);
                return;
            }
            // think about replacing this with switchMap-based solution because of re-subscriptions
            this.routeSubscription = this.route.queryParams.subscribe((params) => {
                if (params.reload) {
                    this.initialized = false;
                    this.navigationNodes = [];
                }
                if (!this.initialized) {
                    this.initialized = true;
                    this.fetchNavigationNodes(params as { path: string });
                }
            });
        });
    }

    ngOnDestroy() {
        this.loggedInUserSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    /**
     * Change the URL and Header Items according to the provided stack.
     * @param navigationStack
     */
    navigationStackUpdate(navigationStack: NavigationNode[]) {
        this.updateHeaderItems(navigationStack);
        this.updateUrlPath(navigationStack);
    }

    flattenNodes(nodes?: NavigationNode[]): NavigationNode[] {
        const flat = nodes ? [...nodes] : [];
        nodes?.forEach((item) => {
            flat.push(...this.flattenNodes(item.nodes));
        });
        return flat;
    }

    /**
     * Set the location in the address bar to match the navigation path.
     * @param navigationPath list of NavigationNodes.
     */
    private updateUrlPath(navigationPath: NavigationNode[]) {
        if (!navigationPath || navigationPath?.length <= 0) return;
        let stringPath = '';
        for (const node of navigationPath) {
            const prefix = node.type === NavigationNodeType.Area ? 'a' : 'c';
            const identifier = prefix + '~' + node.name;
            stringPath = stringPath + identifier;
            if (navigationPath.indexOf(node) !== navigationPath.length - 1) {
                stringPath = stringPath + '.';
            }
        }
        let pathParams: Params = { path: stringPath };
        this.router.navigate([], { queryParams: pathParams });
    }

    private createNavigationStackFromParams(
        params: { path: string },
        navigationNodes: NavigationNode[],
    ): NavigationNode[] {
        const nodeNamePath = params?.path?.split('.') ? params?.path?.split('.') : [];
        const simpleNodes: { name: string; type: NavigationNodeType }[] = nodeNamePath.map((nnp) => {
            return {
                name: nnp.substring(2),
                type: nnp[0] === 'a' ? NavigationNodeType.Area : NavigationNodeType.Category,
            };
        });
        if (navigationNodes.length === 0) return [];
        if (nodeNamePath.length === 0) return [navigationNodes[0]];
        const flattenNodes = (nodes: NavigationNode[]): NavigationNode[] => {
            const flat = [];
            nodes.forEach((node) => {
                if (node.nodes) {
                    flat.push(...flattenNodes(node.nodes));
                }
                flat.push(node);
            });
            return flat;
        };
        const flattenedNotes = flattenNodes(navigationNodes);
        const path = [];
        for (const simpleNode of simpleNodes) {
            const pathNode = flattenedNotes.find((n) => n.name === simpleNode.name && n.type === simpleNode.type);
            if (pathNode) path.push(pathNode);
        }
        return path;
    }

    private fetchNavigationNodes(params: { path: string; reload?: boolean }) {
        if (this.navigationNodes && this.navigationNodes.length > 0 && !params?.reload) return;
        this.getAllNavigationNodesGQL.fetch().subscribe((nodesFetch) => {
            this.navigationNodes = nodesFetch.data.navigationNodes;
            if (nodesFetch.data.navigationNodes.length > 0) {
                const navigationStack = this.createNavigationStackFromParams(params, nodesFetch.data.navigationNodes);
                this.updateHeaderItems(navigationStack);
                this.updateUrlPath(navigationStack);
            }
        });
    }

    private updateHeaderItems(navigationStack: NavigationNode[]) {
        if (!(this.navigationHeaderItems?.length > 0)) {
            this.navigationHeaderItems = this.navigationNodes.map((nn) => {
                return {
                    title: nn.name,
                    secondary: '',
                    navNode: nn,
                    stack: [nn], // this would need to be changed if the stack should be remembered when changing segments
                };
            });
        }
        const currentHeaderItem = this.navigationHeaderItems.find((nhi) => nhi.navNode === navigationStack[0]);
        const currentIndex = this.navigationNodes.indexOf(currentHeaderItem.navNode);
        currentHeaderItem.stack = navigationStack;
        currentHeaderItem.navNode = navigationStack[0];
        if (currentHeaderItem.stack.length > 1) {
            currentHeaderItem.secondary = navigationStack[0].name;
            currentHeaderItem.title = navigationStack[1].name;
        } else {
            currentHeaderItem.title = navigationStack[0].name;
            currentHeaderItem.secondary = null;
        }
        this.headerItemIndex$.next(currentIndex);
    }

    resetNavigation() {
        this.navigationHeaderItems = [];
        this.fetchNavigationNodes({ path: 'a~Organe', reload: true });
    }
}
