import { Component, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'state-value-input-save-modal',
    templateUrl: './state-value-input-save-modal.component.html',
    styleUrls: ['./state-value-input-save-modal.component.scss'],
})
export class StateValueInputSaveModalComponent {
    @Output() cancelled = new EventEmitter();
    constructor(private modalCtrl: ModalController) {}

    async discard() {
        this.cancelled.emit();
        await this.modalCtrl.dismiss(null, 'cancel');
    }

    async save() {
        // closes the save/discard modal
        await this.modalCtrl.dismiss(null, 'save');
    }
}
