import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DwinUiCategory } from '../common/entities/dwin-ui-category';
import { User } from '../auth/user';
import { RouterExtension } from '../router/router.service';
import { AuthService } from '../auth/auth.service';
import packageJson from 'package.json';
import { RequiredActionsService } from '../dwin/services/required-actions/required-actions.service';
import { RequiredAction, RequiredActionType } from '../graphql-generated';

interface DwinSideMenuButton {
    iconPath?: string;
    label: string;
    url: string;
    category: DwinUiCategory;
}

@Component({
    selector: 'dwin-side-menu',
    templateUrl: './dwin-side-menu.component.html',
    styleUrls: ['./dwin-side-menu.component.scss'],
})
export class DwinSideMenuComponent implements OnInit, OnDestroy {
    dwinSideMenuHomeButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-home.svg',
        label: $localize`:@@components.side-menu.home:Missing translation`,
        url: '/feed',
        category: DwinUiCategory.FEED,
    };

    dwinSideMenuChatbotButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-chatbot.svg',
        label: 'Buddy',
        url: '/chatbot',
        category: DwinUiCategory.CHATBOT,
    };

    dwinSideMenuButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-dwin.svg',
        label: 'Dwin',
        url: '/dwin',
        category: DwinUiCategory.DWIN,
    };

    dwinSideMenuProjectButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-projects.svg',
        label: $localize`:@@components.side-menu.projects:Missing translation`,
        url: '/projects',
        category: DwinUiCategory.PROJECTS,
    };

    profileButton: DwinSideMenuButton = {
        iconPath: 'assets/imgs/icon-profile.svg',
        label: $localize`:@@general.account:Missing translation`,
        url: '/profile',
        category: DwinUiCategory.PROFILE,
    };
    sideMenuButtons: DwinSideMenuButton[] = [this.dwinSideMenuButton];
    sideMenuLegalButtons: DwinSideMenuButton[] = [];

    loggedInUserSubscription: Subscription;
    loggedInUser: User;
    appVersion: string;

    private reqActionsSub: Subscription;
    private requiredActions: RequiredAction[] = [];

    displayLegalButtons = false;

    authSideMenuButtons: DwinSideMenuButton[] = [
        this.dwinSideMenuHomeButton,
        this.dwinSideMenuChatbotButton,
        this.dwinSideMenuButton,
        this.dwinSideMenuProjectButton,
    ];

    constructor(
        private router: RouterExtension,
        public menuController: MenuController,
        private authService: AuthService,
        private reqActionsService: RequiredActionsService,
    ) {
        this.appVersion = packageJson.version;
    }

    ngOnInit() {
        this.sideMenuLegalButtons = [
            {
                label: $localize`:@@components.side-menu.privacy-policy:Missing translation`,
                category: DwinUiCategory.PRIVACY,
                url: '/legal/privacy',
            },
            {
                label: $localize`:@@components.side-menu.terms-of-use:Missing translation`,
                category: DwinUiCategory.USER_AGREEMENT,
                url: '/legal/user-agreement',
            },
            {
                label: $localize`:@@components.side-menu.imprint:Missing translation`,
                category: DwinUiCategory.IMPRINT,
                url: '/legal/imprint',
            },
        ];
        this.loggedInUserSubscription = this.authService.loggedInUser.subscribe((user) => {
            this.loggedInUser = user;
            this.updateSideNavButtons();
            if (this.loggedInUser) this.reqActionsService.checkForRequiredActions();
        });
        this.reqActionsSub = this.reqActionsService.requiredActionChanges$.subscribe((ra) => {
            this.requiredActions = ra;
            this.updateSideNavButtons();
        });
    }

    ngOnDestroy() {
        this.loggedInUserSubscription.unsubscribe();
        this.reqActionsSub.unsubscribe();
    }

    updateSideNavButtons() {
        if (this.loggedInUser) {
            this.sideMenuButtons = [...this.authSideMenuButtons];
            const hasLoggedInUserReqActions =
                this.requiredActions &&
                this.requiredActions.filter((ra) => ra.requiredActionType === RequiredActionType.StateCollection)
                    .length > 0;
            if (hasLoggedInUserReqActions) {
                this.sideMenuButtons = this.sideMenuButtons.filter(
                    (smb) => smb.category !== DwinUiCategory.PROJECTS && smb.category !== DwinUiCategory.FEED,
                );
            }
        }
    }

    async navigateToPage(url: string) {
        await this.initUser();
        await this.router.navigate([url]);
        await this.menuController.close();
    }

    async login() {
        await this.authService.authorizationCodeAuth();
    }

    async logout() {
        await this.authService.logout('');
        this.menuController.close();
    }

    private async initUser() {
        const session = this.authService.getSession();
        this.loggedInUser = session ? await this.authService.getSession().user : null;
    }

    toggleLegalButtons() {
        this.displayLegalButtons = !this.displayLegalButtons;
    }
}
