// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.state-values {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: var(--ion-color-tertiary);
  overflow-y: scroll;
}

hr {
  margin-bottom: var(--d-spacing-single);
}

.state-value-input-button-container {
  padding: var(--d-spacing-double);
  padding-top: var(--d-spacing-single);
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10000;
}

ion-grid {
  width: 100%;
  padding: 0;
}

ion-col {
  --ion-grid-column-padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/state-value/components/state-value-input/dwin-state-value-input.component.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AADJ;;AAIA;EACI,aAAA;EACA,iBAAA;EACA,WAAA;EACA,2CAAA;EACA,kBAAA;AADJ;;AAIA;EACI,sCAAA;AADJ;;AAIA;EACI,gCAAA;EACA,oCAAA;EACA,0CAAA;EACA,cAAA;AADJ;;AAIA;EACI,WAAA;EACA,UAAA;AADJ;;AAIA;EACI,4BAAA;AADJ","sourcesContent":["@import 'src/mixins.scss';\n\n.input-content {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-flow: column;\n}\n\n.state-values {\n    display: flex;\n    flex-flow: column;\n    width: 100%;\n    background-color: var(--ion-color-tertiary);\n    overflow-y: scroll;\n}\n\nhr {\n    margin-bottom: var(--d-spacing-single);\n}\n\n.state-value-input-button-container {\n    padding: var(--d-spacing-double);\n    padding-top: var(--d-spacing-single);\n    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);\n    z-index: 10000;\n}\n\nion-grid {\n    width: 100%;\n    padding: 0;\n}\n\nion-col {\n    --ion-grid-column-padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
