import { Injectable, inject } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { MatomoRouterInterceptor, MatomoTracker } from 'ngx-matomo-client';
import { Observable } from 'rxjs';

@Injectable()
export class RouteInterceptor implements MatomoRouterInterceptor {
    private readonly tracker = inject(MatomoTracker);
    private urlPrefixesToDelete = ['capacitor://capacitor://', 'capacitor://capacitor//'];

    beforePageTrack(event: NavigationEnd): void | Observable<void> | Promise<void> {
        return this.normalizeUrl();
    }

    async normalizeUrl() {
        const url = await this.tracker.getCurrentUrl();
        const normalizedUrl = this.urlPrefixesToDelete.reduce((acc, prefix) => acc.replace(prefix, 'http://'), url);
        this.tracker.setCustomUrl(normalizedUrl);
    }
}
