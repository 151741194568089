import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LoadingService } from './loading.service';

@Component({
    standalone: true,
    template: ``,
    imports: [IonicModule],
})
export class LoadingPage {
    constructor(private loadingService: LoadingService) {}

    ionViewWillEnter() {
        this.loadingService.increaseLoadings();
    }

    ionViewDidLeave() {
        this.loadingService.decreaseLoadings();
    }
}
