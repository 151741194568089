import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';

@Component({
    selector: 'error-log',
    templateUrl: './error-log.component.html',
    styleUrls: ['./error-log.component.scss'],
})
export class ErrorLogComponent {
    copied = false;
    private _errorMessages: Error[] = [];

    @Input() set errorMessages(errors: Error[]) {
        this._errorMessages = errors;
    }

    errorMessagesAsJson = () => {
        const replacer = (_this: any, value: { [x: string]: string }) => {
            if (value instanceof Error) {
                const errorObject = {};
                // Copy all properties, including non-enumerable ones
                Object.getOwnPropertyNames(value)
                    .sort() // -> "stack" shouldn't be the first property for better readability
                    .forEach((key) => {
                        errorObject[key] = value[key];
                    });
                return errorObject;
            }
            return value;
        };

        return JSON.stringify(this._errorMessages, replacer, 4);
    };

    constructor(private modalCtrl: ModalController) {}

    close() {
        return this.modalCtrl.dismiss();
    }

    async copyToClipboard() {
        this.copied = true;
        await Clipboard.write({
            string: this.errorMessagesAsJson(),
        });
    }
}
