import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string) {}

    public transform(
        value: Date | string | number,
        variant: 'simple' | 'definedStateValue' | 'calculatedStateValue' = 'simple',
    ): string {
        if (new Date(value).toString() === 'Invalid Date' && new Date(Number(value)).toString() === 'Invalid Date') {
            return String(value);
        }
        const datePipe: DatePipe = new DatePipe(this.locale);
        let timestampText = datePipe.transform(value, 'dd.MM.YYYY');
        const today = datePipe.transform(new Date(), 'dd.MM.YYYY');
        const isToday = today === timestampText;
        const yesterday = datePipe.transform(new Date(new Date().setDate(new Date().getDate() - 1)), 'dd.MM.YYYY');
        const isYesterday = yesterday === timestampText;
        if (variant === 'simple') {
            if (isToday) {
                return $localize`:@@pipe.date-format.today:Missing translation`;
            } else if (isYesterday) {
                return $localize`:@@pipe.date-format.yesterday:Missing translation`;
            } else {
                return timestampText;
            }
        } else if (variant === 'definedStateValue') {
            if (isToday) {
                return $localize`:@@pipe.date-format.state-value-text.today:Missing translation`;
            } else if (isYesterday) {
                return $localize`:@@pipe.date-format.state-value-text.yesterday:Missing translation`;
            } else {
                return $localize`:@@pipe.date-format.state-value-text.date:Missing Translation` + timestampText;
            }
        } else if (variant === 'calculatedStateValue') {
            if (isToday) {
                return $localize`:@@pipe.date-format.calculated-state-value-text.today:Missing translation`;
            } else if (isYesterday) {
                return $localize`:@@pipe.date-format.calculated-state-value-text.yesterday:Missing translation`;
            } else {
                return (
                    $localize`:@@pipe.date-format.calculated-state-value-text.date:Missing Translation` + timestampText
                );
            }
        }
    }
}
